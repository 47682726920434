import React, { createRef, Component } from 'react';

import { Button } from '@material-ui/core';

import styles from './Navbar.module.css';
// import DrawerToggleButton from './components/DrawerToggleButton/DrawerToggleButton';
import UserDetailsModal from '../components/UserDetailsModal/UserDetailsModal';
import UserDetailsDrawer from '../components/MobileComponents/UserDetailsDrawer/UserDetailsDrawer';
import EarlyAccessSuccessModal from '../components/EarlyAccessSuccess/EarlyAccessSuccessModal';
import EarlyAccessSuccessDrawer from '../components/MobileComponents/EarlyAccessSuccessDrawer/EarlyAccessSuccessDrawer';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      successOpen: false,
      visible: false,
      successDrawerOpen: false,
    };
    this.menu_list = createRef(null);
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = (value, e) => {
    // console.log(error)
    e.preventDefault();
    if (value()) {
      this.setState({ open: false, successOpen: true });
    }
  };

  handleSuccessClose = () => {
    this.setState({ successOpen: false });
  };

  // for mobile drawer

  showDrawer = () => {
    this.setState({ visible: true });
  };

  hideDrawer = () => {
    this.setState({ visible: false });
  };

  handleSubmitDrawer = (validation, e) => {
    e.preventDefault();
    if (validation()) {
      this.setState({ visible: false, successDrawerOpen: true });
    }
  };

  hideSuccessDrawer = () => [this.setState({ successDrawerOpen: false })];

  // const toggle_nav = () => {
  //     menu_list.current.classList.toggle(styles['toggle_menu']);
  // }
  render() {
    return (
      <header className={styles.toolbar}>
        <div className="container">
          <nav className={styles.toolbar__navigation}>
            <div className={styles.toolbar__logo}>
              <a href="/">Letters</a>
            </div>
            <div ref={this.menu_list} className={styles.toolbar__navigation_items}>
              <ul>
                <li>
                  <span className={styles.mail_id}>hello@letters.today</span>
                </li>
                <li>
                  <Button onClick={this.handleOpen} className={styles.access_button} variant="contained">
                    Request Early Access
                  </Button>
                </li>
              </ul>
            </div>
            <div className={styles.mobile_access_btn_wrapper}>
              <Button onClick={this.showDrawer} className={styles.mobile_access_button} variant="contained">
                Request Early Access
                <img alt="right_arrow" style={{ marginLeft: '10px' }} src={require('../../../images/SVG/chevron-right.svg')} />
              </Button>
            </div>
            {/* <div onClick={toggle_nav} className={styles.navigation__toggle_button}>
                            <DrawerToggleButton />
                        </div> */}
          </nav>
        </div>
        {this.state.open ? <UserDetailsModal open={this.state.open} handleClose={this.handleClose} handleSubmit={this.handleSubmit} /> : null}
        {this.state.successOpen ? <EarlyAccessSuccessModal successOpen={this.state.successOpen} handleSuccessClose={this.handleSuccessClose} /> : null}

        {/* for mobile */}
        {this.state.visible ? <UserDetailsDrawer visible={this.state.visible} onClose={this.hideDrawer} handleSubmit={this.handleSubmitDrawer} /> : null}
        {this.state.successDrawerOpen ? <EarlyAccessSuccessDrawer successVisible={this.state.successDrawerOpen} hideSuccessDrawer={this.hideSuccessDrawer} /> : null}
      </header>
    );
  }
}

export default Navigation;
