import React, {createRef, Component} from 'react';
import {Modal, Fade, Grid, Button} from '@material-ui/core';

import styles from './UserDetails.module.css';


class UserDetailsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fName: '',
            lName: '',
            email: '',
            error: '',
            errors: '',
            errorBorderF: '',
            errorBorderL: '',
            errorBorderE: ''
        }
        this.f_Name = createRef();
        this.l_Name = createRef()
        this.email_Id = createRef()
    }

    valid = () => {

        const { fName, lName, email } = this.state;
        let formIsValid = true;
        let error = '';
        let errors = '';
        let errorBorderF;
        let errorBorderL;
        let errorBorderE;
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)

        if (fName === "" || lName === "" || email === "") {
            formIsValid = false;
            error = "Please fill all required fields";
        }

        if (fName === "") {
            formIsValid = false;
            errorBorderF = styles.inputError
        }

        if (lName === "") {
            formIsValid = false;
            errorBorderL = styles.inputError
        }

        if (email === "") {
            formIsValid = false;
            errorBorderE= styles.inputError
        }

        if(email !== "") {
            if(!pattern.test(email)) {
                formIsValid = false;
                errors = "Please enter a valid email";
                errorBorderE= styles.inputError
            }
        }

        this.setState({
            error: error,
            errors: errors,
            errorBorderF: errorBorderF,
            errorBorderL: errorBorderL,
            errorBorderE: errorBorderE,
        })
        return formIsValid;
    }
    
    render() {
        const { fName, lName, email, error, errors } = this.state
        return (
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={styles.modal}
                    open={this.props.open}
                    closeAfterTransition
                >
                    <Fade in={this.props.open}>
                    <div className={styles.paper}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <img 
                                onClick={this.props.handleClose} 
                                alt="close_btn" 
                                style={{ cursor: 'pointer' }} 
                                src={require('../../../../images/SVG/ic_close_dark 2.svg')} 
                            />
                        </div>
                        <h2 className={styles.modalHeading} >Request Early Access</h2>
                        <h5 className={styles.error}> {error} </h5>
                        <h5 className={styles.error}> {errors} </h5>
                        <form method="post" className={styles.formContent} onSubmit={(e) => this.props.handleSubmit(this.valid, e, )}>
                            <Grid container spacing={3}>
                                <Grid item md={6} sm={6} xs={12}>
                                    <div className={styles.inputWrapper}>
                                        <label className={styles.form_label}>First Name<span style={{ color: 'red' }}> *</span></label>
                                        <input 
                                            ref={this.f_Name}
                                            className={this.state.errorBorderF}
                                            name="f_name"
                                            type="text"
                                            autoFocus
                                            placeholder="Enter your first name here"
                                            value={fName}
                                            onChange={(e) => this.setState({ fName: e.target.value })}
                                        />
                                    </div>
                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                    <div className={styles.inputWrapper}>
                                        <label className={styles.form_label}>Last Name<span style={{ color: 'red' }}> *</span> </label>
                                        <input 
                                            ref={this.l_Name}
                                            className={this.state.errorBorderL}
                                            name="l_name"
                                            type="text"
                                            placeholder="Enter your last name here"
                                            value={lName}
                                            onChange={(e) => this.setState({ lName: e.target.value })}

                                        />
                                    </div> 
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <div className={styles.inputWrapper}>
                                        <label className={styles.form_label}>Email<span style={{ color: 'red' }}> *</span> </label>
                                        <input 
                                            ref={this.email_Id}
                                            className={this.state.errorBorderE}
                                            name="email_id"
                                            type="text"
                                            placeholder="Enter your e-mail address here"
                                            value={email}
                                            onChange={(e) => this.setState({ email: e.target.value })}

                                        />
                                    </div> 
                                </Grid>
                            </Grid>
                            <Button type="submit" className={styles.buttonWrapper} >Submit</Button>
                        </form>
                    </div>
                    </Fade>
                </Modal>
            </div>
        );
    }
}

export default UserDetailsModal;