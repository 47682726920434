import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header(props) {
  let temp = props.searchBar === false ? 'header borderNone ' : 'header';
  let temp2 = props.searchBar === false ? 'headerOuterDiv ' : 'headerOuterDiv ';
  let marginTop = props.marginTop ? props.marginTop : '0';
  return (
    <div className={temp2} id="headerOuterDiv" style={{ height: props.height || '68px', marginTop: marginTop }}>
      <div className={temp} id="normalHeader">
        <Link to="/" style={{ textDecoration: 'none', color: '#1A172D' }}>
          <div className="logo">Letters</div>
        </Link>

        <div style={{ textDecoration: 'none', color: '#1A172D' }}>
          <div className="features">A casual email experience</div>
        </div>
      </div>
    </div>
  );
}

export default Header;
