import React from 'react';
import {Grid, Typography, useMediaQuery} from '@material-ui/core';

import styles from './OurValue.module.css';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    heading: {
        textAlign: 'left',
        font: 'Bold 30px/46px Poppins',
        letterSpacing: '0px',
        color: '#1A1F27',
        textTransform: 'capitalize',
        opacity: 1,
    },
    subtitle: {
        textAlign: 'left',
        font: 'Bold 18px/33px Poppins',
        letterSpacing: '0px',
        color: '#1A1F27',
        opacity: 1,
    },
    para: {
        textAlign: 'left',
        font: '400 16px/30px Poppins',
        letterSpacing: '0px',
        color: '#1A1F27',
        opacity: 0.6,
        marginBottom: '1em'
    },
    MobileHeading: {
        textAlign: 'left',
        font: 'Bold 24px/20px Poppins',
        letterSpacing: '0px',
        color: '#1A1F27',
        opacity: 1
    },
    MobileSubtitle: {
        textAlign: 'left',
        font: 'Bold 16px/33px Poppins',
        letterSpacing: '0px',
        color: '#1A1F27',
        opacity: 1,
    },
    MobilePara: {
        textAlign: 'left',
        font: '400 14px/28px Poppins',
        letterSpacing: '0px',
        color: '#1A1F27',
        opacity: 0.6,
        marginBottom: '1em',
    }
}));

const OurValueSection = () => {

    const matches = useMediaQuery('(max-width:478px)');
    const classes = useStyles();

    return (
        <div className={styles.value_wrapper}>
            <div className="container">
                <Grid container spacing={3}>
                    <Grid item md={3} sm={3} xs={12}>
                        {
                            matches ? 
                            <Typography variant="h5" gutterBottom className={classes.MobileHeading}>
                                Our Values
                            </Typography>
                            :
                            <Typography variant="h5" gutterBottom className={classes.heading}>
                                Our Values
                            </Typography>
                        }
                    </Grid>
                    <Grid item md={9} sm={9} xs={12}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Grid container spacing={1}>
                                <Grid item md={4} sm={4} xs={12}>
                                    {
                                        matches ?
                                        <Typography component="p" variant="h6" className={classes.MobileSubtitle} gutterBottom>
                                                Be Brilliant
                                        </Typography> 
                                        :
                                        <Typography component="p" variant="h6" className={classes.subtitle} gutterBottom>
                                            Be Brilliant
                                        </Typography>
                                    }
                                </Grid>
                                <Grid item md={8} sm={8} xs={12}>
                                    {
                                        matches ? 
                                        <Typography variant="subtitle1" className={classes.MobilePara} gutterBottom>
                                        Build the best. Work with the best. Become your best. Be brilliant at what you do. 
                                        </Typography>
                                        :
                                        <Typography variant="subtitle1" className={classes.para} gutterBottom>
                                            Build the best. Work with the best. Become your best. Be brilliant at what you do. 
                                        </Typography>
                                    }
                                    
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item md={4} sm={4} xs={12}>
                                    {
                                        matches ?
                                        <Typography component="p" variant="h6" className={classes.MobileSubtitle} gutterBottom>
                                            Create Delight
                                        </Typography>
                                        :
                                        <Typography component="p" variant="h6" className={classes.subtitle} gutterBottom>
                                            Create Delight
                                        </Typography>
                                    }
                                </Grid>
                                <Grid item md={8} sm={8} xs={12}>
                                    {
                                        matches ?
                                        <Typography variant="subtitle1" className={classes.MobilePara} gutterBottom>
                                            Be dedicated to delight. Go to lengths others would consider extreme. If it’s better for users, it’s not over-engineered.
                                        </Typography>
                                        :
                                        <Typography variant="subtitle1" className={classes.para} gutterBottom>
                                            Be dedicated to delight. Go to lengths others would consider extreme. If it’s better for users, it’s not over-engineered.
                                        </Typography>
                                    }
                                    
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item md={4} sm={4} xs={12}>
                                    {
                                        matches ? 
                                        <Typography component="p" variant="h6" className={classes.MobileSubtitle} gutterBottom>
                                            Be Resourceful
                                        </Typography>
                                        :
                                        <Typography component="p" variant="h6" className={classes.subtitle} gutterBottom>
                                            Be Resourceful
                                        </Typography>
                                    }
                                    
                                </Grid>
                                <Grid item md={8} sm={8} xs={12}>
                                    {
                                        matches ?
                                        <Typography variant="subtitle1" className={classes.MobilePara} gutterBottom>
                                            Be relentlessly resourceful. Determined but flexible. Persistent yet open-minded.
                                        </Typography>
                                        :
                                        <Typography variant="subtitle1" className={classes.para} gutterBottom>
                                            Be relentlessly resourceful. Determined but flexible. Persistent yet open-minded.
                                        </Typography>
                                    }
                                    
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item md={4} sm={4} xs={12}>
                                    {
                                        matches ?
                                        <Typography component="p" variant="h6" className={classes.MobileSubtitle} gutterBottom>
                                            Be Swift
                                        </Typography>
                                        :
                                        <Typography component="p" variant="h6" className={classes.subtitle} gutterBottom>
                                            Be Swift
                                        </Typography>
                                    }
                                    
                                </Grid>
                                <Grid item md={8} sm={8} xs={12}>
                                    {
                                        matches ? 
                                        <Typography variant="subtitle1" className={classes.MobilePara} gutterBottom>
                                            Move with speed. Make rapid decisions. While others deliberate, you tackle the next challenge.
                                        </Typography>
                                        :
                                        <Typography variant="subtitle1" className={classes.para} gutterBottom>
                                            Move with speed. Make rapid decisions. While others deliberate, you tackle the next challenge.
                                        </Typography>
                                    }
                                    
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item md={4} sm={4} xs={12}>
                                    {
                                        matches ?
                                        <Typography component="p" variant="h6" className={classes.MobileSubtitle} gutterBottom>
                                            Break The Rules
                                        </Typography>
                                        :
                                        <Typography component="p" variant="h6" className={classes.subtitle} gutterBottom>
                                            Break The Rules
                                        </Typography>
                                    }
                                    
                                </Grid>
                                <Grid item md={8} sm={8} xs={12}>
                                    {
                                        matches ? 
                                        <Typography variant="subtitle1" className={classes.MobilePara} gutterBottom>
                                            Take calculated risks. Play to win. Rules are for those who need them; you do things your own way.
                                        </Typography>
                                        :
                                        <Typography variant="subtitle1" className={classes.para} gutterBottom>
                                            Take calculated risks. Play to win. Rules are for those who need them; you do things your own way.
                                        </Typography>
                                    }
                                    
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item md={4} sm={4} xs={12}>
                                    {
                                        matches ?
                                        <Typography component="p" variant="h6" className={classes.MobileSubtitle} gutterBottom>
                                            Do Good
                                        </Typography>
                                        :
                                        <Typography component="p" variant="h6" className={classes.subtitle} gutterBottom>
                                            Do Good
                                        </Typography>
                                    }
                                    
                                </Grid>
                                <Grid item md={8} sm={8} xs={12}>
                                    {
                                        matches ?
                                        <Typography variant="subtitle1" className={classes.MobilePara} gutterBottom>
                                            It’s not enough to “do no evil”. Be driven to do good. Make a positive impact on the world.
                                        </Typography>
                                        :
                                        <Typography variant="subtitle1" className={classes.para} gutterBottom>
                                            It’s not enough to “do no evil”. Be driven to do good. Make a positive impact on the world.
                                        </Typography>
                                    }
                                    
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default OurValueSection;
