import React, { Component } from 'react';
import { Drawer } from 'antd';
import { Button } from '@material-ui/core';

import styles from './EarlyAccessSuccessDrawer.module.css';

export default class EarlyAccessSuccessDrawer extends Component {
    render() {
        return (
            <div>
                <Drawer
                    placement="bottom"
                    closable={false}
                    onClose={this.props.hideSuccessDrawer}
                    visible={this.props.successVisible}
                    // getContainer={false}
                    height="auto"
                >
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <img 
                                    onClick={this.props.hideSuccessDrawer} 
                                    alt="close_btn" 
                                    style={{ cursor: 'pointer', height: '25px', width: '25px' }} 
                                    src={require('../../../../../images/SVG/ic_close_dark 2.svg')} 
                                />
                        </div>
                        <h2 className={styles.heading}>Congratulations !</h2>
                        <p className={styles.paragraph}>
                            You have been added into the early access list. Once product is ready, we will notify via e-mail.
                        </p>
                        <div className={styles.btn_wrapper}>
                            <Button className={styles.share_Button}>Share with friends</Button>
                        </div>
                    </div>
                </Drawer>
            </div>
        )
    }
}
