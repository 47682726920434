import React from 'react';
import Home from './Home';
import PrivacyPolicy from './PrivacyPolicy';
import { BrowserRouter as Router, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Router>
        {/* <Route path="/" exact component={Home}></Route> */}
        <Route path="/" component={PrivacyPolicy}></Route>
      </Router>
    </div>
  );
}

export default App;
