import React from 'react';
import Header from './view/PrivacyPolicy/Header/Header';
import Body from './view/PrivacyPolicy/Body/Body';

function PrivacyPolicy() {
  return (
    <div className="privacyPolicy">
      <Header />
      <Body />
    </div>
  );
}

export default PrivacyPolicy;
