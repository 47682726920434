import React from 'react';

import {Grid, Typography, useMediaQuery} from '@material-ui/core';

import styles from './WhyJoin.module.css';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    heading: {
        textAlign: 'left',
        font: 'Bold 30px/46px Poppins',
        letterSpacing: '0px',
        color: '#FFFFFF',
        textTransform: 'capitalize',
        opacity: 1,
    },
    paragraph: {
        textAlign: 'left',
        font: '400 16px/30px Poppins',
        letterSpacing: '0px',
        color: '#FFFFFF',
        opacity: 0.6,
        marginBottom: theme.spacing(3)
    },
    designationWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: '10px',
        textAlign: 'left',
        letterSpacing: '0px',
        color: '#FFFFFF',
        opacity: 0.6
    },
    name: {
        textAlign: 'left',
        font: 'Bold 18px/29px Poppins',
        letterSpacing: '0px',
        color: '#FFFFFF',
    },
    designation: {
        textAlign: 'left',
        font: 'Italic 18px/29px Poppins',
        letterSpacing: '0px',
        color: '#FFFFFF'
    },
    mobileHeading: {
        textAlign: 'left',
        font: 'Bold 24px/20px Poppins',
        letterSpacing: '0px',
        color: '#FFFFFF',
        opacity: 1,
        marginBottom: theme.spacing(3)
    },
    mobileParagraph: {
        textAlign: 'left',
        font: '400 14px/28px Poppins',
        letterSpacing: '0px',
        color: '#FFFFFF',
        opacity: 0.6,
        marginBottom: theme.spacing(3)
    },
    mobileName: {
        textAlign: 'left',
        font: 'Bold 14px/22px Poppins',
        letterSpacing: '0px',
        color: '#FFFFFF',
    },
    mobileDesignation: {
        textAlign: 'left',
        font: 'Italic 14px/22px Poppins',
        letterSpacing: '0px',
        color: '#FFFFFF',
    }
}));

const WhyJoinSection = () => {

    const matches = useMediaQuery('(max-width:478px)');
    const classes = useStyles();

    return (
        <div className={styles.whyJoin_wrapper}>
            <div className="container">
                {
                    matches ? 
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} >
                            <Typography variant="h5" gutterBottom className={classes.mobileHeading}>
                                Why Join Letters
                            </Typography>
                            <Typography variant="body2" component='p' gutterBottom className={classes.mobileParagraph}>
                                At Letters, we are driven to do good. We are passionate about productivity. We are serious about increasing wealth creation and positively impacting the economy.
                            </Typography>
                            <Typography variant="body2" component='p' gutterBottom className={classes.mobileParagraph}>
                                Email is a massive point of leverage: there are 1 billion professionals in the world, and on average we spend 3 hours a day reading and writing email. This is more than almost any other aspect of our work.
                            </Typography>
                            <Typography variant="body2" component='p' gutterBottom className={classes.mobileParagraph}>
                                That’s why we’re rebuilding the email experience. It will be better than Gmail & Outlook. So fast, delightful, and intelligent.
                            </Typography>
                            <Typography variant="body2" component='p' gutterBottom className={classes.mobileParagraph}>
                                At Letters, you’ll shape the future of email, communication, and productivity.
                            </Typography>
                            <Typography variant="body2" component='p' gutterBottom className={classes.mobileParagraph}>
                                Let’s show the world how it should be.
                            </Typography>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '2px', height: '45px', background: '#FFFFFF 0% 0% no-repeat padding-box' }} />
                                <div className={classes.designationWrapper}>
                                    <Typography className={classes.mobileName}>
                                        Aravind Raju,
                                    </Typography>
                                    <Typography className={classes.mobileDesignation}>
                                        Co-Founder & CEO
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} >
                            <Typography variant="h5" gutterBottom className={classes.heading}>
                                Why Join Letters
                            </Typography>
                            <Typography variant="body2" component='p' gutterBottom className={classes.paragraph}>
                                At Letters, we are driven to do good. We are passionate about productivity. We are serious about increasing wealth creation and positively impacting the economy.
                            </Typography>
                            <Typography variant="body2" component='p' gutterBottom className={classes.paragraph}>
                                Email is a massive point of leverage: there are 1 billion professionals in the world, and on average we spend 3 hours a day reading and writing email. This is more than almost any other aspect of our work.
                            </Typography>
                            <Typography variant="body2" component='p' gutterBottom className={classes.paragraph}>
                                That’s why we’re rebuilding the email experience. It will be better than Gmail & Outlook. So fast, delightful, and intelligent.
                            </Typography>
                            <Typography variant="body2" component='p' gutterBottom className={classes.paragraph}>
                                At Letters, you’ll shape the future of email, communication, and productivity.
                            </Typography>
                            <Typography variant="body2" component='p' gutterBottom className={classes.paragraph}>
                                Let’s show the world how it should be.
                            </Typography>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '2px', height: '57px', background: '#FFFFFF 0% 0% no-repeat padding-box' }} />
                                <div className={classes.designationWrapper}>
                                    <Typography className={classes.name}>
                                        Aravind Raju,
                                    </Typography>
                                    <Typography className={classes.designation}>
                                        Co-Founder & CEO
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid className={styles.mobileGrid} item xs={12} sm={12} md={6} >
                            <div >
                                <img className={styles.mobileImage} alt="mobile" src={require('../../../images/PNG/Mask Group 8@2x.png')} />
                            </div>
                        </Grid>
                </Grid>
                }
                
            </div>
        </div>
    );
}

export default WhyJoinSection;
