import React from 'react';
import { Typography, useMediaQuery, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  brandName: {
    textAlign: 'left',
    font: 'Bold 27px/40px Poppins',
    letterSpacing: '0px',
    color: '#FFFFFF',
    opacity: 1,
  },
  copyRight: {
    textAlign: 'left',
    font: '400 12px/18px Poppins',
    letterSpacing: '0px',
    color: '#FFFFFF',
    opacity: 0.4,
  },
  emailId: {
    textAlign: 'right',
    font: '500 16px/25px Poppins',
    letterSpacing: '0px',
    color: '#FFFFFF',
    opacity: 1,
  },
  mobilebrandName: {
    textAlign: 'left',
    font: 'Bold 14px/21px Poppins',
    letterSpacing: '0px',
    color: '#FFFFFF',
    opacity: 1,
  },
  MobileCopyRight: {
    textAlign: 'left',
    font: '400 10px/16px Poppins',
    letterSpacing: '0px',
    color: '#FFFFFF',
    opacity: 0.4,
  },
  MobileEmailId: {
    textAlign: 'right',
    font: '500 12px/18px Poppins',
    letterSpacing: '0px',
    color: '#FFFFFF',
    opacity: 1,
  },
});

const Footer = () => {
  const matches = useMediaQuery('(max-width:478px)');
  const classes = useStyles();

  return (
    <div className={styles.footer_wrapper}>
      <div className="container">
        {matches ? (
          <div>
            <Grid container>
              <Grid item md={6} xs={7} lg={6}>
                <Typography className={classes.mobilebrandName} variant="h6" gutterBottom>
                  Letters
                </Typography>
                <Typography className={classes.MobileCopyRight} variant="caption" display="block" gutterBottom>
                  © Letters Inc 2020. All Rights Reserved.
                </Typography>
              </Grid>
              <Grid item md={6} xs={5} lg={6}>
                <Typography className={classes.MobileEmailId} variant="h6" gutterBottom>
                  hello@letters.com
                </Typography>
              </Grid>
            </Grid>
          </div>
        ) : (
          // <div className={styles.inner_content}>
          //     <div className={styles.left_content}>
          //         <Typography className={classes.brandName} variant="h6" gutterBottom>
          //             Letters
          //         </Typography>
          //         <Typography className={classes.copyRight} variant="caption" display="block" gutterBottom>
          //             © Letters Inc 2020. All Rights Reserved.
          //         </Typography>
          //     </div>
          //     <Typography className={classes.emailId} variant="h6" gutterBottom>
          //         hello@letters.com
          //     </Typography>
          // </div>
          <Grid container>
            <Grid item md={6} xs={7} lg={6}>
              <Typography className={classes.brandName} variant="h6" gutterBottom>
                Letters
              </Typography>
              <Typography className={classes.copyRight} variant="caption" display="block" gutterBottom>
                © Letters Inc 2020. All Rights Reserved.
              </Typography>
            </Grid>
            <Grid item md={6} xs={5} lg={6}>
              <Typography className={classes.emailId} variant="h6" gutterBottom>
                hello@letters.today
              </Typography>
              <Link to="/privacy">
                <Typography className={classes.copyRight} variant="caption" display="block" gutterBottom style={{ marginLeft: '400px', marginTop: '20px' }}>
                  Privacy Policy
                </Typography>
              </Link>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default Footer;
