import React from 'react';
import './Body.css';

function Body() {
  return (
    <div className="newPrivacyPolicy">
      <div className="container" style={{ marginTop: '150px' }}>
        <div className="headingTopp" style={{ marginBottom: '20px' }}>
          Privacy Policy
        </div>
        <p className="content">This Privacy Policy applies to the Letters applications (iOS and Android), the Letters website, and the Vumonic website. All Letters websites and applications are owned and operated by Vumonic Datalabs OU (our "Services"). </p>
        <p className="content">
          The purpose of this Privacy Policy is to unambiguously explain how we collect, process, protect, share, use, and disclose information collected through our Services. We are philosophically aligned with the core principles behind CCPA and GDPR, and
          strongly believe in the fundamental data privacy rights of individuals. As a company that always puts our actions exactly in line with our values, we have put great effort into crafting this Privacy Policy.{' '}
        </p>
        <p className="content">
          Privacy Policies and Terms and Conditions should be meticulously read before signing up for any service. We also recognize that this hardly ever happens in practice. Thus, we have outlined what we feel are the most important points below.
        </p>
        <p className="content">We encourage and hope that you will read the full documents, but accept that this is not likely, and want to make sure everyone is as informed as possible.</p>
        <p className="content2">1. We use commercial data found in your email inbox to create and license aggregate market insights</p>
        <p className="content2">2. We never share any personally identifiable information</p>
        <p className="content2">3. We respect your right to opt out at any time</p>
        <p className="content2">4. We respect your right to delete your data from our servers at any time</p>
        <p className="content2">5. We take data privacy as our #1 priority and want to know how we can improve</p>
      </div>
      <div className="container" style={{ marginTop: '60px' }}>
        <div className="heading2New" style={{ marginBottom: '15px' }}>
          Services
        </div>
        <p className="sub_heading">Our services are the following:</p>
        <p className="heading3New">Letters</p>
        <p className="content">Letters is an email client which aims to delight the user.</p>
        <p className="heading3New">Vumonic</p>
        <p className="content">A company that provides anonymized data on online purchase trends.</p>
      </div>
      <div className="container" style={{ marginTop: '60px' }}>
        <div className="heading2New" style={{ marginBottom: '15px' }}>
          Information we collect from Letters
        </div>

        <p className="content">
          We access and process email messages for all connected email accounts that you choose to connect to Letters. By checking the consent box, you are actively consenting to our Terms of Service and Privacy Policy. By consenting, you authorize us to
          collect, process, and retain information, including personal information, from all connected email accounts. We use this information to provide our services to you and, as permitted, use commercial data, which is non-personal data extracted from
          commercial email messages, to help us and Vumonic subscribers understand market, consumer, and competitive trends and behaviors. We want to be very clear that no personally identifiable data is ever transmitted, which we will elaborate on further in
          the privacy policy.
        </p>
        <p className="heading3New">Commercial email messages</p>
        <p className="content">
          Commercial email messages are emails received from businesses. This includes, but is not limited to: orders, transactions, promotions, subscriptions, registrations, cancellations, account requests, customer service inquiries, confirmations, travel or
          event tickets, reservations, boarding passes, bills, and and/or order shipments. We access, store and use commercial email messages to provide our services.{' '}
        </p>
        <p className="content">
          As permitted, we extract information and commercial data, from commercial email messages to provide our services to you and to Vumonic subscribers. We access, store and use the information we collect from all connected email accounts to provide our
          Services.{' '}
        </p>
      </div>
      <div className="container" style={{ marginTop: '60px' }}>
        <div className="heading2New" style={{ marginBottom: '15px' }}>
          Summary
        </div>

        <p className="content">
          We and our Vumonic subscribers use commercial data to develop market research about market trends. We created Vumonic as an all-in-one market intelligence tool that can be used to understand consumer trends across all online markets. Please visit
          vumonic.com to learn more.
        </p>
        <p className="content">
          We do not, and will never, share any data that contains personal information, such as name, phone number, email address, IP address, photo, or physical address. Most research we share is aggregate, meaning that it does not report on individuals, but
          on broad markets as a whole (for example, the taxi app market in India). As such, identities are protected, and commercial email data is used as an input to create these insights.{' '}
        </p>
        <p className="content">Vumonic subscribers are explicitly prohibited from attempting to re-identify users, or otherwise misuse the information we share for anything other than its intended purpose of aggregate market intelligence.</p>
        <p className="heading3New">Right to Opt-Out</p>
        <p className="content">
          You may opt out of having your commercial data used for Vumonic services at any time. By navigating to the privacy section within the “settings” tab on any Letters application or website. We encourage you to exercise this right at any point, now or
          in the future, for any reason whatsoever. For example, in the case that we update our Privacy Policy and Terms and Conditions to something that makes you uncomfortable.
        </p>
        <p className="heading3New">Information collected automatically</p>
        <p className="content">
          Like most modern applications and websites, we gather certain information automatically when you access them. This may include information on device type, operating system and version, referring/exit pages, advertising ID, device identifier (IDFA),
          IP address, browser type, internet service provider, files viewed on our site, date/time stamp, and/or clickstream data. The purpose of this is to understand trends and use of the site for improvement and analysis.
        </p>
        <p className="heading3New">Information collected from cookies and other tracking technologies</p>
        <p className="content">
          We (along with our partners) use cookies and similar technologies to analyze how you interact with our Services and to collect demographic information to understand the composition of our userbase. You can disable these cookies at any time. This is
          common for modern applications and websites.
        </p>
        <p className="heading3New">Information collected from registration, use of services, and website visits</p>
        <p className="content">
          By registering for our services, you may provide us with your information including, but not limited to, type of device, operating system version, your name, date of birth, photo/picture, gender preference, email address, credentials (in an encrypted
          format), and device identifier (IDFA). We may also collect information about your website and app usage, in an effort to improve our Services and your experience, as is common with modern websites.
        </p>
        <p className="heading3New">Information collected from communications with us</p>
        <p className="content">We retain any communications you send us through all channels in an effort to keep a record of all communication and provide the best customer experience.</p>
        <p className="heading3New">Other app usage & website visitor information</p>
        <p className="content">
          We may use automatically collected information to remember information (as to not have to re-enter it), provide custom content/information, determine the effectiveness of our Services, fix technology problems reported by you, and/or automatically
          update the application on your system and related devices.
        </p>
      </div>
      <div className="container" style={{ marginTop: '60px' }}>
        <div className="heading2New" style={{ marginBottom: '15px' }}>
          How we use information
        </div>
        <p className="sub_heading">We use, process, and store your information strictly as necessary to provide services to you and for our legitimate business interests, including to:</p>
        <p className="heading3New">Provide and operate our services</p>
        <p className="content">We use collected information to operate, maintain, provide, and customize our services.</p>
        <p className="heading3New">Use commercially</p>
        <p className="content">
          As permitted and described above, we use collected commercial data to create datasets and market reports which are licensed for use to our partners (third parties). These third parties use the commercial data to create their own trend and insight
          reports, which may be used internally or further licensed to additional third parties.
        </p>
        <p className="heading3New">Analyze usage and diagnose bugs</p>
        <p className="content">
          As with all modern applications and websites, our service may use certain information about you internally for analytical purposes related to user research, security, and bug diagnosis. We use analytics software to better understand the functionality
          of our software on your device. This software may record information such as how often you use the application and other performance-related data.{' '}
        </p>
        <p className="heading3New">Communicate with you</p>
        <p className="content">
          By providing your email address, you consent to receiving emails to the listed email address. You can always opt out of these communications by clicking the ‘unsubscribe’ link in the bottom of these emails. Our emails concern new features, updates,
          and responses to any queries that you send us.
        </p>
      </div>

      <div className="container" style={{ marginTop: '60px' }}>
        <div className="heading2New" style={{ marginBottom: '15px' }}>
          Your information management choices
        </div>

        <p className="heading3New">Right to deletion</p>
        <p className="content">
          If you would like to have all of your retroactive data deleted from our servers, you may do so instantly by navigating to the ‘privacy’ tab within the ‘settings’ (gear) icon on the main menu of our website or application. It is your right to do this
          at any time.
        </p>
        <p className="heading3New">Data retention</p>
        <p className="content">We may retain your information for as long as your account is active or as needed to provide our services, and to comply legally.</p>
        <p className="heading3New">Unsubscribe</p>
        <p className="content">You may unsubscribe at any time from our commercial emails by clicking the unsubscribe button within the email. You will continue to receive administrative messages from us regarding our services.</p>
        <p className="heading3New">Opt-Out of sharing your data</p>
        <p className="content">
          If you would like to opt out from sharing your information to be used as commercial data, you may do so instantly by navigating to the ‘privacy’ tab within the ‘settings’ (gear) icon on the main menu of our website or application. You may do this at
          any time.
        </p>
        <p className="heading3New">Security</p>
        <p className="content">It is your responsibility to keep your passwords secure. Please do not re-use the same password multiple times.</p>
        <p className="content">
          The protection of your information is our absolute highest priority. Due to the personal nature of the data that is stored on our servers, we employ multiple administrative, technical, and physical safeguards to protect information against theft,
          misuse, and/or unauthorized access. Data is encrypted in transit and at rest. While we take precautions to ensure the integrity and security of our network and systems, we cannot guarantee these security measures will prevent third parties from
          obtaining information by illegal actions or attacks.
        </p>
      </div>

      <div className="container" style={{ marginTop: '60px' }}>
        <div className="heading2New" style={{ marginBottom: '15px' }}>
          How we share information
        </div>
        <p className="sub_heading">
          Our responsibility to protect and maintain your privacy is our #1 priority. As permitted and mentioned previously, we make certain information and research available to our subscribers, service providers, partners, and other third parties. These
          third parties may include the following:
        </p>
        <p className="heading3New">Legal compliance</p>
        <p className="content">
          Vumonic may be required to disclose personal information in response to lawful requests by national security, public authorities, and/or law enforcement. We may disclose to conform to the law, to enforce our Terms of Service, to take precautions
          against liability, to comply with legal process served on us or our affiliates, to investigate, prevent, or take action on potential or real illegal activities, to investigate and defend ourselves against any third-party claims or allegations, to
          assist government enforcement agencies, to protect the security of our site, and to exercise and defend the rights, property, and safety of our company, users, services, and others. Unless we are legally prohibited to do so, we will notify you of any
          legal process seeking access to your information.
        </p>
        <p className="heading3New">Service providers and vendors</p>
        <p className="content">
          We work together with service providers who assist us in meeting business operations needs, (such as Amazon) for hosting and delivering our services. Other service providers we work with are related to online communication, customer support,
          technical development, data storage, maintenance, and analytics. These service providers may only access, process, or store personal data pursuant to perform their duties and as allowed by our terms and privacy policies with you.
        </p>
        <p className="heading3New">Marketing & analytics partners</p>
        <p className="content">
          We use marketing and analytics services like Google and Facebook to help us market to new users, understand crash reports and bugs, and track our growth. These third-party partners may receive information about your use of our services. These service
          providers are authorized to use your information only as necessary to provide their services to us, and we only work with trusted partners.{' '}
        </p>
        <p className="heading3New">Corporate change or business transfer</p>
        <p className="content">
          We may also transfer or assign your information in the case of a corporate change or business transfer. This includes, but is not limited to,, mergers, acquisitions, dissolutions, and divestitures. We will continue to take measures to protect the
          confidentiality of personal information and give affected users notice before transferring any personal information to a new entity.{' '}
        </p>
        <p className="heading3New">Vumonic market intelligence</p>
        <p className="content">
          We use the commercial data we collect to create, distribute, and use Vumonic market intelligence as described above. Commercial data shall not be used to provide Vumonic market intelligence to third parties where such use is not permitted.{' '}
        </p>
      </div>

      <div className="container" style={{ marginTop: '60px' }}>
        <div className="heading2New" style={{ marginBottom: '15px' }}>
          Users in California (CCPA):
        </div>
        <p className="content">
          Vumonic does not sell your personal information. Vumonic will not discriminate against you should you exercise your rights under the CCPA. We have prepared a table based on CCPA laws below. All of the information related to data processing in the
          table below is stated elsewhere in this Privacy Policy, and we have included this table only to reference specific laws within CCPA.{' '}
        </p>
        <p className="heading3New">Use and sharing</p>
        <p className="content">Vumonic collects, uses, and shares your personal information as follows:</p>
        <table>
          <tr>
            <th>Class</th>
            <th>Description of Personal Information</th>
            <th>Commercial/Business Purpose</th>
            <th style={{ width: '15%' }}>Collected in last 12 months?</th>
            <th>Category of Third Parties With Whom We Share Your Information With</th>
            <th style={{ width: '13%' }}>Source</th>
          </tr>
          <tr>
            <td>1</td>
            <td>Commercial information, including records of products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</td>
            <td>Provide the Vumonic Apps to you Creation of Commercial Data</td>
            <td>Yes</td>
            <td>Government Entities (if subpoenaed, App Measurement Service Providers, Demographic Services Providers, Email Service Companies, and Marketing Companies.</td>
            <td>Your accounts connected with the Vumonic Apps</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Identifiers such as a real name, postal address, email address, account name, or other similar identifiers.</td>
            <td>To provide services, create Commercial Data, and notify you of any changes or updates to the Services</td>

            <td>Yes</td>
            <td>Government Entities (if subpoenaed, App Measurement Service Providers, Demographic Services Providers, Email Service Companies, and Marketing Companies.</td>
            <td>The accounts that you have connected to the Services.</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Any categories of personal information described in subdivision (e) of Section 1798.80.</td>
            <td>To provide the Services to you</td>
            <td>Yes</td>
            <td>Government Entities (if subpoenaed, App Measurement Service Providers, Demographic Services Providers, Email Service Companies, and Marketing Companies.</td>
            <td>The accounts that you have connected to the Services.</td>
          </tr>
        </table>

        <p className="content">
          Letters allows you to access the full content of your emails, which includes additional categories of personal information. These categories are accessed directly via the Letters app on your device from the email server of your email service provider
          and not collected, used, or disclosed by Vumonic.
        </p>
        <p className="content">If requested for any reason, you may access this notice in an alternative format. Please contact us via email at hello@letters.today.</p>
        <p className="heading3New">Deidentified information</p>
        <p className="content">
          As mentioned above, Vumonic sells commercial data that is deidentified information to its Vumonic subscribers. This is not the same as selling personal information and it is important that you understand the difference. Under the CCPA, personal
          information does not include deidentified information (Cal Civ Code § 1798.140(0)(3)). Please reference the civil code here:{' '}
          <a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=CIV&sectionNum=1798.140." target="_blank">
            https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=CIV&sectionNum=1798.140.
          </a>
        </p>
        <p className="content">
          To be deidentified, information “cannot reasonably identify, relate to, describe, be capable of being associated with, or be linked, directly or indirectly, to a particular consumer” (Cal Civ Code § 1798.140 (h)). Please read it for yourself, and ask
          us any questions on what this means.
        </p>
        <p className="content">
          In order to use deidentified information, we (as required) have implemented technical safeguards that prohibit reidentification of the consumer to whom the information may pertain. We have implemented business processes that prevent accidental
          sharing of deidentified information, and have specifically prohibited reidentification of the information in our agreements with partners.{' '}
        </p>
        <p className="content">
          As a technical safeguard, we deidentify commercial email messages to create commercial data in several ways. We have cyber security controls in place to protect against breaches of both personal information and deidentified information. We do not
          reidentify personal information contained in the commercial data sold to our subscribers.
        </p>
        <p className="heading3New">Who may make a request</p>
        <p className="content">Only you, or a person registered with the California Secretary of State that you authorize to act on your behalf as your agent, may make a request related to your personal information.</p>
        <p className="heading3New">Right Of deletion</p>
        <p className="content">
          You have the right to request deletion of your personal information. Please see above sections to understand how you can delete and otherwise control your data. We strive to make it as simple and straightforward as possible to delete and control your
          data.
        </p>
        <p className="heading3New">Communication response time</p>
        <p className="content">
          Vumonic responds promptly to all requests, but will confirm receipt of requests within 7 days, and respond within 30 days from the date of request. If additional time is needed, Vumonic will notify you. Thank you for being patient with us.{' '}
        </p>
        <p className="heading3New">Denial of requests</p>
        <p className="content">
          If we are unable to verify the request, the request is unlawful, or exceeds your rights under the CCPA, we will deny the request. You have the right to appeal through appropriate means, and we will review each case on an individual basis.
        </p>
        <p className="heading3New">Your right to know</p>
        <p className="content">
          You have the right to request that we disclose what personal information we collect, use, disclose, and sell. To make a request, contact Vumonic via email at hello@letters.today. Please write to us and we will evaluate your request as per the Privacy
          Policy and Terms of Service.
        </p>
        <p className="heading3New">Our commitment to non-discrimination</p>
        <p className="content">
          You have the right to not receive discriminatory treatment by Vumonic for the exercise of your privacy rights under the CCPA. We do not believe in discrimination, hate, or mistreatment on the basis of any identifying demographic whatsoever.{' '}
        </p>
      </div>

      <div className="container" style={{ marginTop: '60px', marginBottom: '100px' }}>
        <div className="heading2New" style={{ marginBottom: '15px' }}>
          Users in the European Economic Area (GDPR):
        </div>
        <p className="sub_heading">Individuals located in the European Economic Area (EEA) have certain rights in respect of your personal information. These include:</p>
        <p className="content2">- The right of access to your personal information;</p>
        <p className="content2">- The right to correct or rectify any inaccurate personal information</p>
        <p className="content2">- The right to restrict or oppose processing of personal information</p>
        <p className="content2">- The right to erase your personal information</p>
        <p className="heading3New" style={{ marginTop: '20px' }}>
          The right to personal information portability
        </p>
        <p className="content">
          In the cases in which you have consented to data processing, your consent provides the legal basis to process your personal information, such as when you consent to processing in relation to the processing of your data for the Services. As per GDPR,
          you have the right to withdraw consent at any time. Any withdrawal of consent to collect and process your personal information does not affect the lawfulness of processing your personal information based on your consent before you withdraw your
          consent.
        </p>
        <p className="content">
          We may also process your personal information on the basis of our legitimate interests, in order to improve the Services. For example, Vumonic has a legitimate interest in collecting information about your device hardware to better understand how
          users access and use the Services. We also have a legitimate interest in providing and developing improved ML and AI algorithms to deliver better product features to our users.{' '}
        </p>
        <p className="content">In some rare cases, Vumonic may process personal information pursuant to legal obligations or to protect your vital interests or those of another person. This has been mentioned above.</p>

        <p className="heading3New">What rights may I exercise as an EEA User?</p>
        <p className="heading4">Right to rectify information:</p>
        <p className="content">Vumonic account holders may access and edit their personal information through their account settings in the Vumonic app.</p>
        <p className="heading4">Right to data portability:</p>
        <p className="content">Users located within the EEA may contact us with questions or requests regarding their personal information by emailing hello@letters.today. We may ask for verification before we disclose any personal information.</p>
        <p className="heading4">Right to deletion:</p>
        <p className="content">If you would like to have all of your retroactive data deleted from our servers, you may do so instantly by navigating to the ‘Privacy’ tab within the ‘Settings’ (gear) icon on the main menu of our website or application.</p>
        <p className="heading4">Right to revoke or withdraw consent:</p>
        <p className="content">
          For the purposes of EU data protection legislation, the "data controller" of your personal information is Vumonic Datalabs OU. Gabriel Appleton is the “DPO”, or Data Protection Officer. You can contact Gabriel directly regarding the processing of
          your personal information by email at hello@letters.today.
        </p>
        <p className="heading4">How we handle Minors’ information</p>
        <p className="content">Our services are not for use by persons under the age of 16. We will immediately delete the account and personal information of any such person that we become aware of.</p>
        <p className="heading4">Right to restrict or oppose:</p>
        <p className="content">You have the right to object to processing of your personal information for legitimate business reasons by using the contact information below. Please note that some processing is essential to providing the Vumonic service.</p>
        <p className="heading4">Links to other sites</p>
        <p className="content" style={{ paddingBottom: '60px' }}>
          Our services may contain links to other sites that are not under our control and have their own privacy policies. Please read over the rules and policies of third party sites before you proceed to use them. We are not responsible for the acts,
          omissions, or content linked on websites, and we provide these links solely for the convenience and information of our users.{' '}
        </p>
      </div>
    </div>
  );
}

export default Body;
