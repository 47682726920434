import React, { Component } from 'react';
import {Modal, Fade} from '@material-ui/core';

import styles from './EarlyAccessSuccess.module.css';
import { Button } from '@material-ui/core';

class EarlyAccessSuccessModal extends Component {
    render() {
        return (
            <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles._modal}
                open={this.props.successOpen}
                closeAfterTransition
            >
                <Fade in={this.props.successOpen}>
                    
                <div className={styles._paper}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <img 
                            onClick={this.props.handleSuccessClose} 
                            alt="close_btn" 
                            style={{ cursor: 'pointer' }} 
                            src={require('../../../../images/SVG/ic_close_dark 2.svg')} 
                        />
                    </div>
                    <h2 className={styles.heading}>Congratulations !</h2>
                    <p className={styles.paragraph}>
                    You have been added into the early access list. Once product is ready, we will notify via e-mail.
                    </p>
                    <div className={styles.btn_wrapper}>
                    <Button className={styles.share_Button}>Share with friends</Button>
                    </div>
                </div>
                </Fade>
            </Modal>
            </div>
        );
    }
}

export default EarlyAccessSuccessModal;